import React from 'react'
import { Landing } from '../../components'
import { Helmet } from 'react-helmet'

function Main() {
    return (
        <div>
            <Helmet>
                <title>Ann Garland</title>
            </Helmet>
            <Landing />
            {/* <ParticleBackground /> */}
            {/* <Navbar /> */}

        </div >
    )
}

export default Main