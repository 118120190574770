import React, { useState, useEffect, useRef } from "react";
import "./AboutMe.css"
import bookcover from './bookcover.jpg';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import bookPicture from './Ann Garland with book.jpg';
import { Navbar } from '../../components'


function AboutMe() {
    const testimonials = [
        {
            id: 1,
            text: "Because I teach courses on psychopathology and psychotherapy, the most common question that undergraduate students have asked me for the past 30 years is: “What do I need to do to become a therapist and/or a scientific practitioner?“.  The most frequent feeling I have when they ask me that question is sadness.  This is because they ask the question too late in their undergraduate career.  When I tell them what they should have done, in terms of the courses to take, clinical and research experience to seek, and work to pursue with faculty members in order to have a remote chance of getting into a graduate program in clinical psychology, counseling or social work, their most common reaction has been: “I had no idea”.  I cannot tell you how terrible it is to look in their eyes when they realize the money they and/or their parents have spent and the opportunity they have missed to achieve their life dream.  If you do not want to end up in this situation, read this book: It will shed light onto the right pathways you have to follow to become who you aspire to be.  It is the most helpful resource to become a psychotherapist and/or a clinical researcher, not only because of the detailed, smart, and relevant information that it contains but because of who is providing you with such information. This book by Dr. Garland is the equivalent of what Ruth Ginsburg would have written to guide someone in getting into law school, Picasso about how to paint, and Beyonce about how to sing.  ",
            author: "Louis G. Castonguay, Ph.D. Liberal Arts Professor of Psychology, Penn State University Former President of the Society for Psychotherapy Research",
        },
        {
            id: 2,
            text: "We are experiencing a crisis in mental health!  The number of youth impacted is increasing, the severity of conditions is escalating, and we are suffering a workforce shortage.  Dr. Garland’s timely book will not only inform but will also inspire students to join the field at a time when providing help and instilling hope is greatly needed.",
            author: "Moisés Barón, Ph.D. President and CEO, San Diego Center for Children President, California Alliance for Child and Family Services (2021)",
        },
        {
            id: 3,
            text: "Selecting a discipline or a degree path can be confusing. Dr. Garland’s important insights clarify the similarities and differences across disciplines, helping aspiring students determine a best fit for their career paths. Dr. Garland explicates a wide array of job opportunities to expand the reader’s imagination regarding the exciting possibilities. In addition to lots of practical advice about how to succeed in graduate school admissions processes, how to choose a graduate program, and how to maximize one’s job prospects, Dr. Garland provides thought-provoking wisdom about the challenges and rewards in a mental health career. It is a must have/must read!",
            author: "Michael A. Lindsey, PhD, MSW, MPH Executive Director, McSilver Institute for Poverty Policy and Research Constance and Martin Silver Professor of Poverty Studies, NYU Silver School of Social Work Aspen Health Innovators Fellow, The Aspen Institute",
        },
        {
            id: 4,
            text: "Dr. Garland’s book is an invaluable resource for all who want a meaningful, fulfilling, and successful career in the mental health field. This essential how-to guide offers comprehensive and timely information that will help the reader understand the unique rewards and challenges of this career path no matter what stage or age they’re at in their professional development. Dr. Garland’s book will empower readers to make informed decisions regarding all aspects of the field including how to shape one’s professional identity along with practical business and legal advice. Throughout the book, Dr. Garland provides numerous examples of her own career decisions along with those of other mental health professionals so that the reader can benefit from valuable diverse perspectives to inform their own choices. I highly recommend this book for anyone who wants to translate their passion into viable and long-lasting opportunities in the mental health profession.",
            author: "Dr. Judy Ho, Clinical and Forensic Neuropsychologist, Tenured Professor, Author of Stop Self-sabotage",
        },
        {
            id: 5,
            text: "Starting the journey of becoming a mental health professional can be exciting, but daunting, for many applicants. Those curious in the field often are unsure where to start their exploratory research--and many do not have the relationships and resources to expand their preparation and strengthen their candidacy as program applicants. Every year, dozens of students reach out to me with questions about how to initiate a path into mental health work, and I am enthusiastic to now have a single resource as a starting point! Knowing how to start is a crucial step for applicants in mental health graduate studies. Dr. Garland makes the critical information needed to enter the field accessible and achievable for a wide range of learners--including First Gen students, historically underrepresented communities, and non-traditional professionals looking to change their career paths. This will be a game-changer for professionals like me who want to encourage entry into the field but have to rely on the navigation of multiple guides, websites, and other (intimidating) resources in order to offer the full and fair experience for future professional helpers.",
            author: "Dr. Drea Letamendi Clinical Psychologist Director of the UCLA Resilience Center (RISE)",
        },
        // Add more testimonials as needed
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        // Start the auto-rotation when component mounts
        startAutoRotation();

        // Clean up the interval when component unmounts
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    const startAutoRotation = () => {
        intervalRef.current = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
        }, 15000); // Adjust the interval time (in milliseconds) as needed
    };

    const handlePrev = () => {
        clearInterval(intervalRef.current);

        setActiveIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
        startAutoRotation();
    };

    const handleNext = () => {
        clearInterval(intervalRef.current);

        setActiveIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
        startAutoRotation();
    };

    // const handleMouseEnter = () => {
    //     setIsHovered(true);
    //     clearInterval(intervalRef.current);
    // };

    // const handleMouseLeave = () => {
    //     setIsHovered(false);
    //     startAutoRotation();
    // };


    return (
        <section className="about-main-container">
            <div className="about-background-container" />
            <div className="about-landing-container">
                <div className="navbar">
                    <Navbar />
                    <hr className="navbar-separator" />
                </div>
                <div className="author-description">
                    <div className="author-book-profilePicture">
                        <img src={bookPicture} alt="Profile Picture" />
                    </div>
                    <div className="author-bio">
                        <div className="author-content">
                            <h1>About the Book</h1>
                            <p>In our country's critical mental health crisis, compassionate and effective professionals are in dire need. If you're passionate about making a difference and considering a fulfilling career in mental health, this book is your essential guide.</p>
                            <p>Gain valuable insights and insiders' perspectives on the realities and challenges of the mental health profession. Explore various disciplines such as counseling, social work, marital & family therapy, psychology, psychiatry, and psychiatric nursing, understanding their similarities and differences. Make informed decisions about your educational path, with practical advice on graduate school admissions, cost reduction, salary maximization, and job options.</p>
                            <p><i>Pursuing a Career in Mental Health</i> is your comprehensive roadmap to demystify the world of mental health, empower your choices, and help you thrive in this vital profession.</p>                        </div>
                        <div className='buttons'>
                            <a href="https://www.amazon.com/Pursuing-Career-Mental-Health-Comprehensive-dp-0197544711/dp/0197544711/ref=mt_other?_encoding=UTF8&me=&qid=1651527785" target="_blank" rel="noopener noreferrer" className="amazon-btn">
                                Buy on Amazon
                            </a>
                        </div>
                    </div>
                </div>

                <hr className="separator" />
                <div className="container-center">
                    <div className="body-center">
                        <h1>About the Author</h1>
                        <p>Ann Garland was born and raised in San Diego, California and moved north to attend U.C. Berkeley
                            where she was a proud member of the swim team. After college, she worked in a group home for young
                            men diagnosed with autism and other severe mental illnesses and learned many essential lessons about
                            mental health care.</p>

                        <p>Ann earned a Master’s Degree in Developmental Psychology at Teachers College of Columbia University
                            and secured a job as a research assistant there working on studies of adolescent suicide prevention
                            programs. After a few years gaining valuable research experience, she returned to graduate school,
                            earning a Ph.D. in Clinical Psychology from Yale University. In an excerpt from the book captured below, Ann shares insights on her career path:</p>

                        <p>I often speak with students who are in a hurry to finish their education. I absolutely understand the wish
                            to finish school as soon as possible to start one’s professional career and begin earning a commensurate
                            salary. However, I’ve seen this impatience backfire when students find that the path they pursued
                            quickly is not a good fit for their career aspirations. The sidetracks I took were well worth it. The mental
                            health worker job in the group home and the research job in New York taught me so much and were
                            absolutely instrumental in clarifying my career choices, not to mention meeting amazing friends and
                            mentors. One thing I’ve learned is that it is foolhardy to assume that our personal or professional paths
                            will progress in a perfect linear direction. Perhaps we learn the most through the unexpected detours,
                            roundabouts, or U-turns.</p>
                        <p>—Ann Garland, PhD</p>


                    </div>
                </div>

                <hr className="separator" />
                <div className="container-center">
                    <div className="body-center">
                        <h1>Testimonials</h1>
                        <div
                            className="testimonial-container"
                        // onMouseEnter={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                        >
                            <button className="testimonial-btn" onClick={handlePrev}>
                                &lt;
                            </button>
                            <div className="testimonial-content">
                                <p className="testimonial-text">{testimonials[activeIndex].text}</p>
                                <p className="testimonial-author">- {testimonials[activeIndex].author}</p>
                            </div>
                            <button className="testimonial-btn" onClick={handleNext}>
                                &gt;
                            </button>
                        </div>
                    </div>
                </div>
                {/* <hr className="separator" />
                <div className="container-center">
                    <div className="body-center">
                        <h1>Background</h1>
                        <p>Ann earned her PhD in Clinical Psychology from Yale University in
                            1994 and completed her clinical internship at UCSD. She also earned a
                            Master’s degree in Human Development from Teachers College,
                            Columbia University, and a Bachelor’s degree in Psychology from U.C.
                            Berkeley, where she was a proud member of the women’s swim team.</p>
                        <p>Ann was a Professor in Psychiatry at UCSD for 20 years, where she
                            was also the Associate Director of the Child and Adolescent Services
                            Research Center. Subsequently, she joined the University of San Diego
                            as the Founding Chair and Professor in the Department of Counseling
                            &amp; MFT. She is currently a consultant to local, national and international
                            mental health organizations.</p>
                        <p>Ann is a native San Diegan who still enjoys swimming, proudly
                            earning a silver medal in the 2022 “Escape from Alcatraz” swim.</p>
                    </div>
                </div> */}
                <div id="contacts" className="contact-form">
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe-25LVg00copAwVn928ZpOiXGk1-Ij4eHp-GLFMBtG1WXHfg/viewform?embedded=true" scrolling="no">Loading…</iframe>
                </div>

            </div >
        </section >

    );
}

export default AboutMe