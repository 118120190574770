import "./Landing.css"
import { NavHashLink as NavLink } from 'react-router-hash-link';
import profilePicture from './ProfilePicture.jpg';
import { Navbar } from '../../components'


function Landing() {

    return (
        <section className="main-container">
            <div className="background-container" />
            <div className="landing-container">
                <div className="navbar">
                    <Navbar />
                    <hr className="navbar-separator" />
                </div>
                <div className="author-description">
                    <div className="author-profilePicture">
                        <img src={profilePicture} alt="Profile Picture" />
                    </div>
                    <div className="author-bio">
                        <div className="author-content">
                            <h6>Psychologist</h6>
                            <h1>Ann Garland, PhD</h1>
                            <p>Ann Garland, PhD has been a licensed psychologist in California for over 25 years. As a Professor, she has led training programs for psychology, counseling, and MFT students at UCSD and USD. She is a nationally recognized expert on ways to improve mental health care by strengthening the integration of innovative science and the healing arts. Drawing on her decades of experience, she wrote the acclaimed book, <i>Pursuing a Career in Mental Health: A Comprehensive Guide for Aspiring Professionals</i> (published by Oxford University Press in 2022). In 2022 she was named a Canada Fulbright Research Chair in Mental Health and Societal Wellbeing.</p>
                        </div>
                        <div className='buttons'>
                            <a href="#contacts" className="btn1">
                                Contact
                            </a>
                            <NavLink
                                to='/About'
                                smooth={true}
                                spy='true'
                                duration={2000}
                                className="btn2"
                            >
                                Learn More
                            </NavLink>
                        </div>
                    </div>
                </div>

                <hr className="separator" />
                <div className="container-center">
                    <div className="body-center">
                        <h1>Psychotherapy Practice</h1>
                        <p>Ann serves clients who are age 15 and above. Her areas of specialization include: Parenting, Anxiety, Depression, Trauma, Grief, Coping with Stress, and Identity exploration (e.g., personal and professional identity issues). Her psychotherapy approach is founded on evidence-based practices, with balanced respect for the unique needs of clients and the healing power of a strong therapeutic relationship. Ann offers sessions in-person (in Del Mar) and remotely.</p>
                        <div className='buttons'>
                            <a href="https://annfgarland.clientsecure.me" target="_blank" rel="noopener noreferrer" className="btn3">
                                Click here for more details on Ann’s Private Practice
                            </a>
                        </div>
                    </div>
                </div>

                <hr className="separator" />
                <div className="container-center">
                    <div className="body-center">
                        <h1>Clinical Supervision and Mental Health
                            Career Consultation</h1>
                        <p>Ann is available for ongoing individual or triadic clinical supervision as required for licensure. She is also available for individual consultation regarding preparation for a successful mental health career, including decisions about graduate school, application strategies, and expanded career opportunities. Ann offers sessions in-person (in Del Mar) and remotely.</p>
                        {/* <div className='buttons'>
                            <a href="https://ann-garland.clientsecure.me" target="_blank" rel="noopener noreferrer" className="btn3">
                                Click here for more details on Ann’s Private Practice
                            </a>
                        </div> */}
                        <div className='buttons'>
                            <a href="#contacts" className="btn3">
                                Contact Ann about Clinical Supervision
                            </a>
                        </div>
                    </div>
                </div>

                <hr className="separator" />
                <div className="container-center">
                    <div className="body-center">
                        <h1>Background</h1>
                        <p>Ann earned her PhD in Clinical Psychology from Yale University in
                            1994 and completed her clinical internship at UCSD. She also earned a
                            Master’s degree in Human Development from Teachers College,
                            Columbia University, and a Bachelor’s degree in Psychology from U.C.
                            Berkeley, where she was a proud member of the women’s swim team.</p>
                        <p>Ann was a Professor in Psychiatry at UCSD for 20 years, where she
                            was also the Associate Director of the Child and Adolescent Services
                            Research Center. Subsequently, she joined the University of San Diego
                            as the Founding Chair and Professor in the Department of Counseling
                            &amp; MFT. She is currently a consultant to local, national, and international
                            mental health organizations.</p>
                        <p>Ann is a native San Diegan who still enjoys swimming, proudly
                            earning a silver medal in the 2022 “Escape from Alcatraz” swim.</p>
                    </div>
                </div>
                <div id="contacts" className="contact-form">
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe-25LVg00copAwVn928ZpOiXGk1-Ij4eHp-GLFMBtG1WXHfg/viewform?embedded=true" scrolling="no">Loading…</iframe>
                </div>

            </div >
        </section >

    );
}

export default Landing;
