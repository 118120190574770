import React from 'react'
import { AboutMe } from '../../components'
import { Helmet } from 'react-helmet'

function About() {
    return (
        <div>
            <Helmet>
                <title>Ann Garland</title>
            </Helmet>
            <AboutMe />
            {/* <ParticleBackground /> */}



        </div >
    )
}

export default About