import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Main, About } from './pages'

function App() {
  return (
    <div className='app'>
      <Router>
        <Switch>
          <Route path='/' exact component={Main} />
          <Route path='/About' exact component={About} />

          <Redirect to="/" />
        </Switch>
      </Router>

    </div>
  );
}

export default App;
