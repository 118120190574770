import React, { useState } from 'react';
import './Navbar.css';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import { FaHome, FaTimes } from 'react-icons/fa';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className={`nav ${menuOpen ? 'menu-open' : ''}`}>
            <div className="left-nav">
                <NavLink to="/">
                    <FaHome className="landing--social" style={{ color: 'black' }} aria-label="Home" />
                </NavLink>
            </div>
            <button className="menu-toggle" onClick={toggleMenu}>
                {menuOpen ? <FaTimes className="menu-icon" /> : <span className="menu-icon"></span>}
            </button>

            <ul>
                <li className="navbar desktop-only">
                    <a href="/" className="home-btn1">
                        Home
                    </a>
                </li>

                <li className="navbar">
                    <NavLink to="/About" smooth={true} spy={true} duration={2000}>
                        Learn about Ann's book: "Pursuing a Career in Mental Health"
                    </NavLink>
                </li>
                <li className="navbar">
                    <a href="https://annfgarland.clientsecure.me" target="_blank" rel="noopener noreferrer">
                        Private Practice
                    </a>
                </li>
                <li className="navbar">
                    <a href="#contacts" className="btn1">
                        Contact
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
